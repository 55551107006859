import { Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Hotel, MyHotel } from '../../../types/hotel';

const ContainerPaper = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(2),
	paddingLeft: theme.spacing(10),
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(1),
	marginLeft: theme.spacing(2),
	marginRight: theme.spacing(1),
	cursor: 'pointer',
	position: 'relative',
	'&:hover': {
		background: theme.palette.primary.light,
	},
}));

const LogoContainerDiv = styled('div')(({ theme }) => ({
	background: '#f1f1f1',
	width: '15%',
	paddingBottom: '15%',
	position: 'absolute',
	top: theme.spacing(-2),
	left: theme.spacing(-2),
	borderRadius: theme.shape.borderRadius,
}));

const LogoHolderDiv = styled('div')({
	display: 'flex',
	position: 'absolute',
	top: 0,
	bottom: 0,
	left: 0,
	right: 0,
	alignItems: 'center',
});

const LogoImg = styled('img')({
	maxWidth: '100%',
});

type HotelChooserHandleProps = {
	hotel: MyHotel;
	currentWorkHotel: Hotel | null;
	handleHotelSelection: (hotel: MyHotel) => () => void;
};

export const HotelChooserHandle: React.FC<HotelChooserHandleProps> = ({
	hotel,
	currentWorkHotel,
	handleHotelSelection,
}) => (
	<ContainerPaper
		onClick={handleHotelSelection(hotel)}
		sx={
			currentWorkHotel && hotel.id === currentWorkHotel.id
				? {
						backgroundColor: 'primary.main',
						color: 'primary.contrastText',
					}
				: {}
		}
	>
		<LogoContainerDiv>
			<LogoHolderDiv>
				{hotel.logo ? (
					<LogoImg src={hotel.logo} alt={hotel.brand} />
				) : (
					<LogoImg src="/brand-logos/unknown.png" alt={hotel.brand} />
				)}
			</LogoHolderDiv>
		</LogoContainerDiv>
		<Typography variant="caption" component="div" sx={{ textAlign: 'right' }}>
			{hotel.id} / {hotel.city}
		</Typography>
		<Typography variant="h6" component="h2">
			{hotel.name}
		</Typography>
	</ContainerPaper>
);
