import { HotelFeature, Hotel } from '../types/hotel';

export const isEnable = (currentWorkHotel: Hotel, feature?: HotelFeature, date = new Date()) => {
	if (!feature) {
		return true;
	}

	// feature is enabled if date argument has a year greater or equal than hotel settings for this particular feature
	const featureFlag = currentWorkHotel.features[feature];
	return (
		(typeof featureFlag === 'boolean' && featureFlag === true) ||
		(typeof featureFlag === 'number' && featureFlag <= date.getFullYear())
	);
};
