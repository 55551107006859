import { Hotel } from '../types/hotel';
import { serializeError } from 'serialize-error';
import { post } from './api';

// POST /error
const trackError = (
	error: Error | null,
	errorInfo: Record<string, unknown>,
	account: { email: string | null } | null,
	currentWorkHotel: Hotel | null,
	extraData: Record<string, unknown> = {},
) => {
	console.info('trackError', error, errorInfo, account, currentWorkHotel, extraData);
	if (process.env.NODE_ENV === 'development') {
		return Promise.resolve();
	}
	return post('/api/error', {
		error: !!error && serializeError(error),
		errorInfo,
		hotelId: !!currentWorkHotel && currentWorkHotel.id,
		userName: !!account && account.email,
		url: window.location.href,
		extraData,
	});
};

export { trackError };
