import { Prettify } from './prettify';

export type ListCriterias = {
	orderBy: string;
	order: 'asc' | 'desc';
	search: string;
	page: number;
	limit: number;
};

export type Page<T> = Prettify<
	{
		docs: T[];
		total: number;
		pages: number;
	} & ListCriterias
>;

export const listCriteriasToUrlSearchParam = (criterias: ListCriterias) => {
	const urlSearchParams = new URLSearchParams();
	Object.entries(criterias).forEach(([name, value]) => {
		urlSearchParams.set(name, `${value}`);
	});
	return urlSearchParams;
};
