import { styled } from '@mui/material/styles';

type HotelChooserCityNameProps = {
	cityName: string;
};

const CityNameContainerDiv = styled('div')(({ theme }) => ({
	paddingTop: '30px',
	borderBottom: '1px solid',
	fontSize: '2em',
}));

export const HotelChooserCityName: React.FC<HotelChooserCityNameProps> = ({ cityName }) => {
	return <CityNameContainerDiv>{cityName}</CityNameContainerDiv>;
};
