import i18next from 'i18next';
import { useCallback } from 'react';
import { Action, createActionsHook, createStateHook, createStore } from 'react-sweet-state';
import { fetchAccount } from '../services/account';
import { logout } from '../services/auth';
import { fetchHotel } from '../services/hotels';
import { Account, AccountAuthorization } from '../types/account';
import { NONE } from '../types/authorizationLevels';
import { Hotel, MyHotel } from '../types/hotel';

// ****************************************************************************
// Store
// ****************************************************************************
type State = {
	id: string | null;
	initializing: boolean;
	email: string | null;
	lastname: string | null;
	firstname: string | null;
	isChangingHotel: boolean;
	myHotels: MyHotel[];
	currentWorkHotel: Hotel | null;
	authorizationLevelMax: AccountAuthorization['level'];
	authorizations: Record<string, AccountAuthorization>;
};

const initialState: State = {
	initializing: true,
	id: null,
	email: null,
	lastname: null,
	firstname: null,
	myHotels: [],
	isChangingHotel: false,
	currentWorkHotel: null,
	authorizationLevelMax: NONE,
	authorizations: {},
};

const setCurrentWorkHotel =
	(
		myHotel: MyHotel | undefined,
		afterHotelChangeCallback?: (hotel: Hotel) => void,
	): Action<State> =>
	async ({ setState }) => {
		if (myHotel) {
			setState({
				initializing: true,
			});
			const hotel = await fetchHotel(myHotel.id);
			(['en', 'fr'] as const).forEach((language) => {
				i18next.addResourceBundle(
					language,
					'hotel-yieldSegmentsFamilies',
					hotel.translations.yieldSegmentsFamilies[language],
				);
				i18next.addResourceBundle(
					language,
					'hotel-yieldChannels',
					hotel.translations.yieldChannels[language],
				);
			});
			localStorage.setItem('currentWorkHotelId', hotel.id);
			setState({
				isChangingHotel: false,
				currentWorkHotel: hotel,
				initializing: false,
			});
			afterHotelChangeCallback && afterHotelChangeCallback(hotel);
		} else {
			setState({
				isChangingHotel: true,
			});
		}
	};

const initAuth =
	(account?: Account): Action<State> =>
	async ({ setState, getState, dispatch }) => {
		try {
			const { id, authorizations, email, lastname, firstname, myHotels } = await ((account &&
				Promise.resolve(account)) ||
				fetchAccount());
			const authorizationLevelMax = Object.values(authorizations)
				.map((authorization) => authorization.level)
				.reduce((accu, level) => Math.max(accu, level), NONE);
			setState({
				id,
				initializing: false,
				email,
				lastname,
				firstname,
				myHotels,
				authorizations,
				authorizationLevelMax,
			});
			const storedCurrentWorkHotelId = localStorage.getItem('currentWorkHotelId');
			const currentWorkHotel =
				myHotels.length === 1
					? myHotels[0]
					: storedCurrentWorkHotelId
						? myHotels.find((h) => h.id === storedCurrentWorkHotelId)
						: undefined;
			setCurrentWorkHotel(currentWorkHotel)({ setState, getState, dispatch });
		} catch (err) {
			console.info("error while getting account info, it's ok", err);
			// silent fails
			// it's ok, it just means that user is not authenticated
			setState({
				initializing: false,
			});
		}
	};
const updateAccount =
	(account: Account): Action<State> =>
	({ setState }) => {
		setState(account);
	};

const signout = (): Action<State> => {
	return ({ setState }) => {
		localStorage.removeItem('currentWorkHotelId');
		setState({ ...initialState, initializing: false });
	};
};

const Store = createStore({
	initialState,
	actions: {
		setCurrentWorkHotel,
		initAuth,
		updateAccount,
		signout,
	},
	name: 'AuthStore',
});

// ****************************************************************************
// State hooks
// ****************************************************************************
export const useIsAuthenticated = createStateHook(Store, {
	selector: (state) => state.email !== null,
});

export const useIsAuthInitializing = createStateHook(Store, {
	selector: (state) => state.initializing,
});

// FIXME [typescript] avoid explicit typing
export const useCurrentWorkHotel: () => Hotel | null = createStateHook(Store, {
	selector: (state) => state.currentWorkHotel,
});

export const useCurrentWorkHotelForSure = () => {
	const currentWorkHotel = useCurrentWorkHotel();
	if (currentWorkHotel === null) {
		throw new Error('currentWorkHotel should be defined');
	}
	return currentWorkHotel;
};

export const useIsChangingHotel = createStateHook(Store, {
	selector: (state) => state.isChangingHotel,
});

export const useMyHotels = createStateHook(Store, {
	selector: (state) => state.myHotels,
});

export const useAuthorizations = createStateHook(Store, {
	selector: (state) => state.authorizations,
});

export const useAuthorizationLevelMax = createStateHook(Store, {
	selector: (state) => state.authorizationLevelMax,
});

const useAccount = createStateHook(Store, {
	selector: (state) => ({
		id: state.id,
		email: state.email,
		lastname: state.lastname,
		firstname: state.firstname,
	}),
});

export const useAccountForSure = () => {
	const { id, email, lastname, firstname } = useAccount();
	if (id === null) {
		throw new Error('id should be defined');
	}
	if (email === null) {
		throw new Error('email should be defined');
	}
	if (lastname === null) {
		throw new Error('lastname should be defined');
	}
	if (firstname === null) {
		throw new Error('firstname should be defined');
	}
	return { id, email, lastname, firstname };
};

// ****************************************************************************
// Action hooks
// ****************************************************************************
const useUserInternalActions = createActionsHook(Store);

export const useUserActions = () => {
	const { initAuth, updateAccount, setCurrentWorkHotel, signout } = useUserInternalActions();
	const businessSignout = useCallback(() => {
		logout().finally(() => {
			signout();
		});
	}, [signout]);
	return {
		initAuth,
		updateAccount,
		setCurrentWorkHotel,
		signout: businessSignout,
	};
};
