import { Hotel, HotelForAdmin } from '../types/hotel';
import { ListCriterias, listCriteriasToUrlSearchParam, Page } from '../types/pagination';
import { get } from './api';

// GET /hotels
export const fetchHotels = (listCriterias: ListCriterias) => {
	return get<Page<HotelForAdmin>>('/api/hotels', listCriteriasToUrlSearchParam(listCriterias));
};

// GET /hotel/xxx
export const fetchHotel = (hotelId: string) => {
	return get<Hotel>(`/api/hotels/${hotelId}`);
};
