import AccountIcon from '@mui/icons-material/AccountCircle';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HotelIcon from '@mui/icons-material/LocationCity';
import LockIcon from '@mui/icons-material/Lock';
import SignoutIcon from '@mui/icons-material/PowerSettingsNew';
import SecurityIcon from '@mui/icons-material/Security';
import TrendingIcon from '@mui/icons-material/TrendingUp';
import ViewListIcon from '@mui/icons-material/ViewList';
import { Drawer, Grid } from '@mui/material';
import { useMemo } from 'react';
import * as authorizationLevels from '../../services/authorizationLevels';
import { HotelFeature } from '../../types/hotel';
import { isEnable } from '../../utils/isEnable';
import { isNotNull } from '../../utils/isNotNull';
import {
	useAuthorizationLevelMax,
	useCurrentWorkHotelForSure,
	useMyHotels,
	useUserActions,
} from '../../utils/user';
import { Brand } from './Brand';
import { BusinessRoute } from './businessRoutes';
import { LinkList } from './LinkList';

type SidebarProps = {
	toggleSidebar: any;
	sidebarOpen: any;
};

const businessRoutes: (BusinessRoute & {
	authorizationLevel: (typeof authorizationLevels.ALL)[number];
	requiredFeature?: HotelFeature;
})[] = [
	// ------------------------------------------------------------------------------------------------
	// YIELD MANAGEMENT
	// ------------------------------------------------------------------------------------------------
	{
		type: 'link',
		to: '/yield-management/flash',
		icon: DashboardIcon,
		labelKey: 'menu.label.home',
		authorizationLevel: authorizationLevels.VIEW,
	},
	{
		type: 'link',
		to: '/yield-management/detailed-analysis',
		icon: DashboardIcon,
		labelKey: 'menu.label.detailed-analysis',
		authorizationLevel: authorizationLevels.VIEW,
	},
	{
		type: 'link',
		to: '/yield-management/monthly-results',
		icon: AssignmentIcon,
		labelKey: 'menu.label.yield-management-monthly-results',
		authorizationLevel: authorizationLevels.VIEW,
	},
	{
		type: 'link',
		to: '/yield-management/daily-analysis',
		icon: AssignmentIcon,
		labelKey: 'menu.label.yield-management-daily-analysis',
		authorizationLevel: authorizationLevels.VIEW,
	},
	{
		type: 'link',
		to: '/yield-management/pick-up',
		icon: TrendingIcon,
		labelKey: 'menu.label.yield-management-pick-up',
		authorizationLevel: authorizationLevels.VIEW,
	},
	{
		type: 'link',
		to: '/yield-management/rm-analysis',
		icon: TrendingIcon,
		labelKey: 'menu.label.yield-management-rm-analysis',
		authorizationLevel: authorizationLevels.VIEW,
	},
	// ------------------------------------------------------------------------------------------------
	// ACCOUNTING
	// ------------------------------------------------------------------------------------------------
	{
		type: 'link',
		to: '/accounting/daily-sales',
		icon: DashboardIcon,
		labelKey: 'menu.label.accounting-daily-sales',
		authorizationLevel: authorizationLevels.VIEW,
		requiredFeature: 'startYearAccounting',
	},
	// ------------------------------------------------------------------------------------------------
	// DOWNLOAD CENTER
	// ------------------------------------------------------------------------------------------------
	{
		type: 'link',
		to: '/download-center',
		icon: DownloadIcon,
		labelKey: 'menu.label.download-center',
		authorizationLevel: authorizationLevels.VIEW,
	},
	// ------------------------------------------------------------------------------------------------
	// AUTHORIZATIONS
	// ------------------------------------------------------------------------------------------------
	{
		type: 'divider',
		authorizationLevel: authorizationLevels.WRITE,
	},
	{
		type: 'link',
		to: '/authorizations',
		icon: SecurityIcon,
		labelKey: 'menu.label.authorizations',
		authorizationLevel: authorizationLevels.WRITE,
	},
	// ------------------------------------------------------------------------------------------------
	// ADMINISTRATION
	// ------------------------------------------------------------------------------------------------
	{
		type: 'divider',
		authorizationLevel: authorizationLevels.ADMIN,
	},
	{
		type: 'link',
		to: '/admin/users',
		icon: ViewListIcon,
		labelKey: 'menu.label.admin-users',
		authorizationLevel: authorizationLevels.ADMIN,
	},
	{
		type: 'link',
		to: '/admin/hotels',
		icon: ViewListIcon,
		labelKey: 'menu.label.admin-hotels',
		authorizationLevel: authorizationLevels.ADMIN,
	},
];

export const Sidebar: React.FC<SidebarProps> = ({ toggleSidebar, sidebarOpen }) => {
	const currentWorkHotel = useCurrentWorkHotelForSure();
	const { setCurrentWorkHotel, signout } = useUserActions();
	const myHotels = useMyHotels();
	const hotelChooserNavigation: BusinessRoute[] = useMemo(() => {
		const RouteOrNull: (BusinessRoute | null)[] = [
			{ type: 'divider' },
			myHotels.length > 1
				? {
						type: 'action',
						action: () => setCurrentWorkHotel(undefined),
						icon: HotelIcon,
						labelKey: 'menu.label.hotel-chooser',
					}
				: null,
		];
		return RouteOrNull.filter(isNotNull);
	}, [myHotels, setCurrentWorkHotel]);
	const personalNavigation: BusinessRoute[] = useMemo(
		() => [
			{ type: 'divider' },
			{
				type: 'localeSwitcher',
			},
			{
				type: 'link',
				to: '/me/my-account',
				icon: AccountIcon,
				labelKey: 'menu.label.myaccount',
			},
			{
				type: 'link',
				to: '/me/my-password',
				icon: LockIcon,
				labelKey: 'menu.label.mypassword',
			},
			{
				type: 'action',
				action: signout,
				icon: SignoutIcon,
				labelKey: 'menu.label.signout',
			},
		],
		[signout],
	);
	const accountAuthorizationLevelMax = useAuthorizationLevelMax();
	const routes = useMemo(
		() =>
			businessRoutes
				.filter(
					(route) =>
						!route.authorizationLevel ||
						accountAuthorizationLevelMax >= route.authorizationLevel,
				)
				.filter(
					(route) =>
						route.type === 'divider' ||
						isEnable(currentWorkHotel, route.requiredFeature),
				),
		[accountAuthorizationLevelMax, currentWorkHotel],
	);
	return (
		<Drawer
			open={sidebarOpen}
			onClose={toggleSidebar}
			sx={{ '.MuiDrawer-paper': { background: 'rgba(0, 0, 0, 0.9)', width: '320px' } }}
		>
			<Grid
				container
				alignItems="stretch"
				direction="column"
				wrap="nowrap"
				sx={{
					minHeight: '100vh',
					padding: '0 15px',
				}}
			>
				<Grid item>
					<Brand toggleSidebar={toggleSidebar} />
				</Grid>
				<Grid item xs>
					<LinkList toggleSidebar={toggleSidebar} routes={routes} />
				</Grid>
				<Grid item>
					<LinkList toggleSidebar={toggleSidebar} routes={hotelChooserNavigation} />
				</Grid>
				<Grid item>
					<LinkList toggleSidebar={toggleSidebar} routes={personalNavigation} />
				</Grid>
			</Grid>
		</Drawer>
	);
};
