import { Card, CardContent, Grid, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { MyHotel } from '../../../types/hotel';
import { useCurrentWorkHotel, useMyHotels, useUserActions } from '../../../utils/user';
import { NoContextPage } from '../../NoContextPage/NoContextPage';
import { HotelChooserCityName } from './HotelChooserCityName';
import { HotelChooserHandle } from './HotelChooserHandle';

export const HotelChooser = () => {
	const currentWorkHotel = useCurrentWorkHotel();
	const { setCurrentWorkHotel } = useUserActions();
	const myHotels = useMyHotels();
	const myHotelsPerCity = myHotels.reduce<Record<string, MyHotel[]>>((accu, myHotel) => {
		const cityHotels = accu[myHotel.city] || [];
		cityHotels.push(myHotel);
		accu[myHotel.city] = cityHotels;
		return accu;
	}, {});
	const myHotelsPerCityAsArray = Object.entries(myHotelsPerCity).sort(([city1], [city2]) =>
		city1.localeCompare(city2),
	);
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const afterHotelChangeCallback = useCallback(
		(newCurrentWorkHotel: MyHotel) => {
			if (!currentWorkHotel || currentWorkHotel.id !== newCurrentWorkHotel.id) {
				// user switch from currentWorkHotel to newCurrentWorkHotel
				// redirecting user to latest available date for this new hotel
				navigate(location.pathname.replace(/^(.*)\/\d{4}-\d{2}-\d{2}$/, '$1'));
			}
		},
		[currentWorkHotel, location, navigate],
	);
	const changeCurrentWorkHotel = useCallback(
		(hotel: MyHotel) => () => {
			setCurrentWorkHotel(hotel, afterHotelChangeCallback);
		},
		[setCurrentWorkHotel, afterHotelChangeCallback],
	);
	return (
		<NoContextPage>
			<Card>
				<CardContent>
					<Grid
						container
						direction="column"
						wrap="nowrap"
						sx={{ maxHeight: '90vh', overflow: 'scroll' }}
					>
						<Grid item>
							<Typography variant="h5">{t('hotel-chooser.title')}</Typography>
						</Grid>
						{myHotelsPerCityAsArray.map(([cityName, hotels]) => (
							<React.Fragment key={cityName}>
								<Grid item>
									<HotelChooserCityName cityName={cityName} />
								</Grid>
								<Grid item>
									{hotels.map((hotel) => (
										<HotelChooserHandle
											key={hotel.id}
											hotel={hotel}
											currentWorkHotel={currentWorkHotel}
											handleHotelSelection={changeCurrentWorkHotel}
										/>
									))}
								</Grid>
							</React.Fragment>
						))}
					</Grid>
				</CardContent>
			</Card>
		</NoContextPage>
	);
};
